  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0px;
    display: block;
  }
  
  
  a {
    color: black;
    text-decoration: none;
  }
  .nav_logo{
    width: 90%;
    height: 3%;
    margin: 10px;
  }
  .Header{
    display: flex;
    width: 100%;
    height: 101px;
    background-color: rgb(255, 255, 255);
  }
  .Header-contatier{
    margin-top: 41px;
    margin-right: 20px;
    display: flex;
    position: absolute;
    right: 0;
  }
  .Header-item{
    padding-right: 40px;
  }